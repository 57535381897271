import clsx from "clsx";
import React from 'react';

export type InstagramProps = {
    className?: string;
};

export const Instagram: React.FC<InstagramProps> = ({className}) => {

    return (
        <svg className={clsx('', className)} viewBox="0 0 19.259 19.255">
            <path
                d="M9.626,6.928a4.937,4.937,0,1,0,4.937,4.937A4.929,4.929,0,0,0,9.626,6.928Zm0,8.146a3.209,3.209,0,1,1,3.21-3.209,3.215,3.215,0,0,1-3.21,3.209Zm6.29-8.348a1.151,1.151,0,1,1-1.151-1.151A1.149,1.149,0,0,1,15.917,6.726ZM19.186,7.9a5.7,5.7,0,0,0-1.555-4.034A5.736,5.736,0,0,0,13.6,2.305c-1.59-.09-6.355-.09-7.944,0A5.728,5.728,0,0,0,1.618,3.856,5.717,5.717,0,0,0,.062,7.891c-.09,1.59-.09,6.355,0,7.944A5.7,5.7,0,0,0,1.618,19.87a5.743,5.743,0,0,0,4.034,1.555c1.59.09,6.355.09,7.944,0a5.7,5.7,0,0,0,4.034-1.555,5.736,5.736,0,0,0,1.555-4.034c.09-1.59.09-6.35,0-7.94Zm-2.054,9.646a3.249,3.249,0,0,1-1.83,1.83c-1.267.5-4.275.387-5.676.387s-4.413.112-5.676-.387a3.249,3.249,0,0,1-1.83-1.83c-.5-1.267-.387-4.275-.387-5.676s-.112-4.413.387-5.676a3.249,3.249,0,0,1,1.83-1.83c1.267-.5,4.275-.387,5.676-.387s4.413-.112,5.676.387a3.249,3.249,0,0,1,1.83,1.83c.5,1.267.387,4.275.387,5.676S17.635,16.278,17.133,17.541Z"
                transform="translate(0.005 -2.238)" fill="currentColor"/>
        </svg>

    );
};
