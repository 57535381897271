export const globalData =  {
    'title': 'SHEARER Creative Studio',
    'url': 'https://shearer.studio',
    'titleDefaultExtension': 'Cut & Color Spezialist in Krefeld',
    'defaultDescription': 'Die Spezialisten für angesagte Farben & kreative Schnitte. Dein genderneutraler Friseur im Herzen Krefelds. Jetzt online Termin buchen!',
    'defaultOgImage': 'default-og-image.png',
    'phoneDisplay': '+49151 68467666',
    'phoneLink': '+4915168467666',
    'phoneLinkWhatsAppHref': 'https://wa.me/4915168467666',
    'facebook': 'https://www.facebook.com/shearer.studio/',
    'instagram': 'https://www.instagram.com/shearer.studio/',
    'pinterest': 'https://www.pinterest.de/shearercreativestudio/',
    'googleMapsLink': 'https://www.google.com/maps/place/Nordwall+60,+47798+Krefeld/@51.3361816,6.5589028,18.2z/data=!4m5!3m4!1s0x47b8a5540fcdf463:0xede368e71b21aa34!8m2!3d51.336154!4d6.5590803',
    'address' : {
        'street': 'Nordwall',
        'number' : '60',
        'city': 'Krefeld',
        'zip': '47798',
    },
    'location' : {lat: 51.336154, lng: 6.5590803},
    'email': 'hello@shearer.studio',
}
